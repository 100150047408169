<template>
  <div>
    <h1>Run Course</h1>
    <p>Before you run the course you can check the settings used during the race, such as 'de-activate autostart', 'voice support' and 'log interval' in the settings menu. Some of the settings can be changed during the race, but not all of them.</p>

    <strong>Run the course</strong>
    <p>Open the course you want to run and press 'Run course'. The button is de-activated if it is outside the opening hours.</p>

    <p>Make sure that you allow 'location in background', otherwise the app will not function properly.</p>

    <p>Wait until the GPS position has been established. Check that the accuracy is good (&lt; 10m), which is shown in the upper left corner.</p>

    <p>The app supports 4 types of courses and the punching depends on the course type. You must always begin with the start point and end with the finish point.</p>
    <ul>
      <li><strong>Standard orienteering</strong> - you punch at the control points in the given order. Manual punching can be done max 50 meters from the next control point. You can force a manual punch at a longer distance than 50 meters, but that means that your result will be disqualified. You can anyhow continue to run the course to take the rest of the control points.</li>
      <li><strong>All in any order</strong> - you punch at the control points in any order, but you have to punch at all control points. Manual punching can be done max 50 meters from the nearast control point.</li>
      <li><strong>Rogaining</strong> - you punch at the control points in any order. You can end the race at any time by punching at the finish point. If you have not taken all control points then you have to punch manually at the finish point to end the race. Manual punching can be done max 50 meters from the nearast control point.</li>
      <li><strong>Scatter orienteering</strong> - you punch at the control points in any order. You can punch at the finish point when you have taken the specified number of control points. Manual punching can be done max 50 meters from the nearast control point.</li>
    </ul>
    <strong>Punching</strong>
    <p>The app supports two main methods for punching: GPS position and QR code as described below. A course has either GPS position or QR code as punching method, never both methods.</p>
    <p>Go to the starting point and punch using the punch method defined for the course. This starts the time measurement.</p>
    <p>Visit the control points according to the type of course (standard orienteering, rogaining etc.). Punch at the finish point to complete the race.</p>

    <span style="text-decoration: underline;">Punch method GPS position</span>
    <p>The app will punch automatically when you reach the position of the control point. It will beep to notify that it has been taken and change to next control point.</p>
    <p>If the app doesn't punch automaticallt although you are on the position of the control point, then you can punch manually. The reason can be that the GPS accuracy is too bad (for example in dense forest), the map is not correctly georeferenced or skewness in the map.</p>
    <p>Manual punching can be done either by clicking the control point button or by slowly turning the smartphone vertically back and forth a few times (|_|_|_). If you have the smartphone on your arm, then stand still and raise and lower your arm a few times to punch manually. You can disable gesture punching in the <a href="https://gpso.se/features/settings">'App features - Settings'</a>.</p>

    <span style="text-decoration: underline;">Punch method QR code</span>
    <p>Press the QR code icon in the app when you reach the position of the control point. This will open the camera to enable scanning of the QR code. A successful scanning of the correct QR code will perform the punch and the app will beep to notify that the control point has been taken. </p>

    <p><img class="img-run-overview"  alt="" src="../assets/run_overview.png" /></p>
    <p>Figure 1: Ongoing standard orienteering race</p>
    <p><img class="img-run-table" alt="" src="../assets/run_table.png" /></p>
    <p>Figure 2: Rogaining race. Remaining time and control points and their values.</p>
    <strong>LiveTrack</strong>
    <p>You can share your position with the LiveTrack feature. LiveTrack lets spectators see your current position during the race. To follow runners with LiveTrack requires the Premium version.</p>
    <ul>
      <li>As a runner, turn on LiveTrack when you start the course by clicking on the LiveTrack icon on the map.</li>
      <li>As a spectator, open the course and and click on the map. A green LiveTrack icon means that there is an active runner. You can select which runners to follow by clicking on the LiveTrack icon.</li>
    </ul>

    <strong>Orienteering support</strong>
    <p>You can get extra orienteering support during the race if that is set as allowed in the course properties. You can get different support depending on the course type (standard, rogaining etc.):</p>
    <ul>
      <li>Show your current position on the map.</li>
      <li>Auto-rotate the map to the north</li>
      <li>Show distance and direction to next control point (standard-orienteering only).</li>
      <li>Voice message for distance and direction to next control point (standard-orienteering only).</li>
      <li>Voice message for course drift (standard-orienteering only).</li>
    </ul>
    <p><img class="img-run-orienteering-support" alt="" src="../assets/run_orienteering_support.png" /></p>
    <p>If you use orienteering support it will be noted in your result.</p>

    <strong>Publish result</strong>
    <p>When you have punched at the finish control point the result is automatically saved and you can publish it, if you like. Select 'Complete result' if you want to upload both punches and your track or 'Punches only' if you don't want to show your track.</p>

  </div>
</template>

<script>
export default {
  name: 'View-AppFeaturesRunCourse'
}
</script>

<style scoped>

  /* Mobile View */
  .mobile-view .img-run-overview {
    width: 50%;
  }
  .mobile-view .img-run-table {
    width: 50%;
  }
  .mobile-view .img-run-orienteering-support {
    width: 60%;
  }
</style>