<template>
  <div class="content">
    <h1>Getting Started</h1>
    <hr width='100%'>
    <p>To run your first course, you have two options: participating in an event or creating your own course.</p>
    <p>If you want to participate in an event, someone else has already created and published the course, and you just need to download it. For more information, check out the 'Participate in an event' section below.</p>

    <h3>Get the app</h3>
    <p>First things first, you'll need to get the app. You can find it on the Google Play Store (Android) or the App Store (iOS). The app is free, so everyone can participate in an event. To access all features you need to get a Premium account, either as a 10-day pass or a subscription.</p>
    <div class="get-app-button-container">
      <a class="get-app-button" href="https://play.google.com/store/apps/details?id=se.hippsomapp.gpsorientering">
        <span>Get it on Google Play for <b>Android</b></span>
        <img src="../assets/gpso_app_icon.png" style="margin-left: 20px"/>
      </a>
      <a class="get-app-button" href="https://apps.apple.com/app/id1555855545">
        <span>Get it on AppStore for <b>iPhone</b></span>
        <img src="../assets/gpso_app_icon.png" style="margin-left: 20px"/>
      </a>
    </div>
    <p> You can find a comparison of the different versions and their features on the <a href="https://gpso.se/features/comparison">'App features'</a> page. </p>

    <h2>Create a course</h2>
    <p>If you want to create a course on your own and run it, then do the following:</p>
    <ol>
      <li>You need a digital orienteering map that is georeferenced. There are some alternatives (see <a href="https://gpso.se/features/maps">'App features - Maps'</a> for details):</li>
      <ul>
        <li>Check with your club if they already have a georeferenced map for the app.</li>
        <li>Your club may have a georeferenced map from a map tool, like OCAD or Condes and then you can this map with georef data (kmz, kml or world file). Or you can georeference the map yourself.</li>
        <li>Or you can search for maps on the internet or scan a paper map, that you georeference yourself.</li>
      </ul>
      <li>You can create a course in different ways (see <a href="https://gpso.se/features/courses">'App features - Courses'</a> for details):</li>
      <ul>
        <li>Create the course from scratch in the app. Create 'New course', add the control points and export a course map for printing.</li>
        <li>Import a course that you have created with another tool, like OCAD, Condes or PurplePen in gpx, kml or kmz format.</li>
        <li>Copy an existing course and make changes to the course.</li>
      </ul>
      <li>Set the punching method, either 'GPS position' or 'QR code'. 'GPS position' means that punching is done based on your position and 'QR code' means that you scan the QR code to punch.</li>
      <ul>
        <li>If you selected 'QR code' as punch method, then print the QR codes generated by the app and place them on their corresponding control points.</li>
      </ul>
      <li>Now you are ready to run the course. Select your course, start the race and wait until the GPS has contact and the accuracy is about 15 meter or better. Go to the starting point and do your first punch, either automatically if the punch method is 'GPS position' or by scanning the QR code if the punch method is 'QR code'.</li>
      <li>Run to the control points, one by one. The app will beep to confirm that you have punched. </li>
      <li>When you have reached the finish point, the result will be saved and you can see it under 'Result'. For more info, see  <a href="https://gpso.se/features/results">'App features - Results'</a>.</li>
      </ol>

      <h1>Event</h1>
      <hr width='100%'>
      <p>'GPS Orienteering' offers a simple way to arrange and participate in training events.</p>
      <p>Follow the steps:</p>
      <ol>
        <li>The person who will arrange the training event create a course in the app and and publish it, see 'Create an event' below</li>
        <li>The particants download the course, run the course and publish their result, see 'Participate in an event' below</li>
        <li>The participants and others who are interested can download the result from all participants and see a result table for the event and details for each participant, see 'Event result' below.</li>
      </ol>

      <h2>Create an event</h2>
      <p>It is simple to arrange a training event with the GPS Orienteering app. Note that publishing a course requires Premium account.</p>
      <ol>
        <li>Create a course using the app, see 'Create a course' above. Remember to place the QR codes at the correct locations, if you have selected 'QR code' as punch method.</li>
        <li>Publish the course by selecting 'Publish' when you have set the course and specified the opening time. The opening hours can be changed also after the course has been published.</li>
        <li>You also need to publish the map itself to make it available for the participants. Open the map used for the course and select 'Publish' in the option menu. It is not necessary to have the map in the app to be able to run the course.</li>
        <li>Give the course code to the participants using the function 'Share course code' in the options menu when you have opened the course view. The participants can either scan the QR code or enter the 8 character code manually.</li>
        <li>Give the participants a paper map with the course. You can create a printable map with the 'Export' function, see <a href="https://gpso.se/features/courses">'App features - Courses'</a></li>
        <li>Now the preparations are done and the participants can download the course, see 'Participate in an event' below).</li>
      </ol>
      <p>The event does not necessarily have to occur at a specific time or day, but can be stretched in time like 'course of the month'.</p>

      <h2>Participate in an event</h2>
      <p>If you will participate in an event you need to have the app. Premium account is <b>not</b> needed to participate in an event.</p>
      <p>The person arranging the event needs to publish the course (and the map), so you can download it. To download the course:</p>
      <ol>
        <li>Get the QR code for the course or the 8 character course code via SMS, email or similar from the one arranging the event.</li>
        <li>Select 'Add course' under 'Courses' and scan QR code or enter the 8 character code. The course (and the map) will now be downloaded to your smartphone.</li>
        <li>You are now ready to run the course. Open the course and select 'Run the course'. Make sure that you try to start the course within its opening hours.</li>
        <li>When you have run the course you can publish your result. Open your result and select 'Publish'. You can select between uploading the complete result (leg times and route) or your punches only (leg times only).</li>
      </ol>
      <p>Follow the runners during the race with LiveTrack.</p>

      <p>See <a href="https://gpso.se/features/run-course">'App features - Run a course'</a> for more info.</p>

      <h2>Event result</h2>
      <p>You can compare results from a training event, both if you have run it yourself several times or if other runners have published their result.</p>

      <p>You can see the results either on the web (<a href="https://gpso.se">https://gpso.se</a>) or in the app.</p>
      <ol>
        <li>Open the course and select 'View results'.</li>
        <li>Your latest run is shown at the top and a table with all your results and all published results is shown below.</li>
        <ul>
          <li>The results are shown in a table with the best result first.</li>
          <li>'DSQ' means disqualified. The result contains a 'forced punch'.</li>
          <li>'DNF' means 'Did not finish'. The race was aborted.</li>
          <li>'S' means that orienteering support was used</li>
          <li>'M' means that one or more control points were punched manually</li>
          <li>'F' means that one or more control points were 'forced punch', i.e. a manuel punch at a distance greater than 50 meters from the control point.</li>
        </ul>
        <li>Click on a result to see leg times, route etc. See <a href="https://gpso.se/features/results">'App features - Results'</a> for more info.</li>
      </ol>
      <img class="img-results" alt="" src="../assets/event_result.png"/>  
  </div>
</template>

<script>
export default {
    name: "View-GettingStarted",
}
</script>

<style scoped>

  .get-app-button-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .get-app-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    text-decoration: none;
    color: #fff;
    background-color: var(--secondary-color);
    margin: 5px;
  }

  .get-app-button:hover {
    opacity: 0.9;
  }
  .get-app-button img {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  }

  /* Desktop View */
  .desktop-view .img-results{
    width: 500px;
  }

  .desktop-view .get-app-button {
    padding: 10px 15px;
    width: 280px;
  }

  .desktop-view .get-app-button img {
    max-width: 50px;
  }

  /* Mobile View */
  .mobile-view .img-results{
    width: 100%;
  }

  .mobile-view .get-app-button {
    padding: 10px 10px;
    width: 280px;
  }

  .mobile-view .get-app-button img {
    max-width: 40px;
  }

</style>